import { SPageListProps } from 'servisofts-component'

const ServiceName = "fullparts";

import sucursal from './Components/sucursal';
import almacen from './Components/almacen';
import container from './Components/container';
import repuesto from './Components/repuesto';
import Carrito from './Components/Carrito';
import Venta from './Components/Venta';
const Pages: SPageListProps = {
    ...sucursal.Pages,
    ...almacen.Pages,
    ...container.Pages,
    ...repuesto.Pages,
    ...Carrito.Pages,
    ...Venta.Pages
}

const Reducers = {
    ...sucursal.Reducers,
    ...almacen.Reducers,
    ...container.Reducers,
    ...repuesto.Reducers,
    ...Carrito.Reducers,
    ...Venta.Reducers
}

export default {
    ServiceName,
    Pages,
    Reducers,
};