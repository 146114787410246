import { SThemeThemes } from 'servisofts-component'
const SThemeProps: SThemeThemes = {
    default: {
        barStyle: "light-content",
        barColor: "#999999",
        text: "#000000",
        primary: "#999999",
        secondary: "#000000",
        info: "#DE5738",
        background: "#dddddd",
        card: "#dddddd66"
    },
    dark: {
        barStyle: "light-content",
        barColor: "#000000",
        text: "#ffffff",
        primary: "#000000",
        secondary: "#ffffff",
        info: "#DE5738",
        background: "#222222",
        card: "#33333366"
    }
}

const SocketProps = {
    debug:true,
    name: 'fullparts',
    
    host: 'fullparts.servisofts.com',
    ssl: true,

    // host: '192.168.3.2',
    // ssl: false,

    port: {
        native: 10026,
        web: 20026,
        http: 30026,
    },
    cert: "MIID3DCCAsSgAwIBAgIEYa2O1TANBgkqhkiG9w0BAQsFADCBrzELMAkGA1UEBhMCQk8xEjAQBgNVBAgMCUF2IEJhbnplcjETMBEGA1UEBwwKU2FudGEgQ3J1ejEXMBUGA1UECgwOU2Vydmlzb2Z0cyBTUkwxEjAQBgNVBAsMCWZ1bGxwYXJ0czEhMB8GA1UEAwwYZnVsbHBhcnRzLnNlcnZpc29mdHMuY29tMScwJQYJKoZIhvcNAQkBFhhyaWNreS5wYXouZC45N0BnbWFpbC5jb20wHhcNMjExMjA2MDQxNzI1WhcNMjExMjA3MDQxNzI1WjCBrzELMAkGA1UEBhMCQk8xEjAQBgNVBAgMCUF2IEJhbnplcjETMBEGA1UEBwwKU2FudGEgQ3J1ejEXMBUGA1UECgwOU2Vydmlzb2Z0cyBTUkwxEjAQBgNVBAsMCWZ1bGxwYXJ0czEhMB8GA1UEAwwYZnVsbHBhcnRzLnNlcnZpc29mdHMuY29tMScwJQYJKoZIhvcNAQkBFhhyaWNreS5wYXouZC45N0BnbWFpbC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDDpG8sDogT+84wZHmEC397SHLDSdsBsGS3KzgwlfK5GxxKXAuK9Dm0G6FwEmoVEjHPwkeI2jan3NwvAJdbo4k2pBw3gXx5OkbbkI7r9VhoJ2gf6TGhRKD35s8/LVljg4h3w590klnGG7yOfJ86teJkhv9TK193nSbGW/rY1l7D9vAr+cDgmq3058ymUnPPKRkaviAmYI9BQGyptcT8KoT8t+fEnO+cALlOXk3F05//B8S9kk5mPESMiQFNaX9EcoC9mYkckYnT2ijsG5uLBXkebXQKNsqrAkNMMuUcEy/hcMYDK6Jo8ieJUItQkUsovlRWnZ1AbDrLRLONm9MlOJBtAgMBAAEwDQYJKoZIhvcNAQELBQADggEBABo+b3om9/8UwrHWn1tsq8krvuYnhMVPwHG6mBuaKq5+ovRJgTCLLzEfS9D0xeCBWfSadrup7BChrtU18YdRZ2xyjfYDfrGwCQacEbMqEmMR4hgpfJa/IxOmycdm67YS6eBpL+fHNJ/MTf97KSVSrWdQTTR8fqSsSVEtiHHGqWdZsC3X08XGfFkd18km3ee+rTnanvhQCVemwgHiVoiy8yXehtN7VNlqEewz61P0sRWpJOYKEdm9LEU2ua0vr9O62RXEXnWWOiv9O9qQ6gtlHIfNJwNFYxRAdwBZ57oGiGapQnZSC38zAZdgYWWWw02ibd3EVldExb9T9b5tSTsj5mM=",
    apis: {
        // servicio: "http://servicio.ss.lo/http/",
        roles_permisos: "https://rolespermisos.servisofts.com/http/"
    }
}
export default {
    SocketProps,
    SThemeProps
}