
import Usuario from './Usuario';
import Roles_permisos from './Roles_permisos';
import Fullparts from './Fullparts';
const Pages = {
    ...Usuario.Pages,
    ...Roles_permisos.Pages,
    ...Fullparts.Pages,
}

const Reducers = {
    ...Usuario.Reducers,
    ...Roles_permisos.Reducers,
    ...Fullparts.Reducers,
}

export default {
    Pages,
    Reducers
}