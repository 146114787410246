import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SIcon, SLoad, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component';
import Parent from '../../index'
import Canvas from './Canvas';
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.key_sucursal = SNavigation.getParam("key_sucursal");
    }

    getContent() {
        var data = Parent.Actions.getAll(this.key_sucursal, this.props);
        if (!data) return <SLoad />;
       return <Canvas data={data} />;
    }

    render() {
        return (
            <SPage title={'Canvas de ' + Parent.component} disableScroll center>
                {this.getContent()}
            </SPage >
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);