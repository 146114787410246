import SSocket from 'servisofts-socket';
export default class index {
    static component = 'venta';
    static reducer = "ventaReducer";

    static getAll = (props, force) => {
        var reducer = props.state[index.reducer];
        var data = reducer.data;
        if (!data || force) {
            if (reducer.estado == "cargando") return;
            SSocket.send({
                component: index.component,
                type: "getAll",
                estado: "cargando",
            })
            return;
        }
        return data;

    }
}