

import { SAssets } from 'servisofts-component'



import Logo, { ReactComponent as LogoW } from './svg/logo.svg';
import LogoWhite, { ReactComponent as LogoWhiteW } from './svg/logoWhite.svg';
const Assets: SAssets = {
    svg: {
    
      

        "Logo": { Native: Logo, Web: LogoW },
      
        "logowhite": { Native: LogoWhite, Web: LogoWhiteW },

    }
}

export default Assets;