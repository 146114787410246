
export default class index {

    static component = 'carrito';
    static reducer = "carritoReducer";

    static getAll = (props) => {
        var reducer = props.state[index.reducer];
        var data = reducer.data;
        return data;

    }


}