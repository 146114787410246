import Actions from './Actions'

import CarritoPage from './Pages/CarritoPage';
const Pages = {
    "carrito": CarritoPage
}
import carritoReducer from './Reducer/carritoReducer';
const Reducers = {
    carritoReducer
}

export default {
    Pages,
    Actions,
    Reducers
};