import SSocket from 'servisofts-socket';
// import Parent from './index';

// const Petition = {
//     component: Parent.component,
//     version: Parent.version,
// }
export default class Actions {
    
    static getAll = () => {

        
    }

}