import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { SButtom, SDate, SForm, SHr, SIcon, SImage, SInput, SNavigation, SOrdenador, SPage, SPopup, SText, STheme, SThread, SView } from 'servisofts-component';
import Carrito from '..';
import SSocket from 'servisofts-socket';
class CarritoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this._ref = {};
    }
    buildCode = () => {
        var code = (new Date().getTime()) % 1000;
        code = code.toFixed(0)
        var code2 = (new Date().getTime() / 1000) % 1000;
        code2 = code2.toFixed(0)
        var code3 = (new Date().getTime() / 100000) % 1000;
        code3 = code3.toFixed(0)
        return `${code}-${code2}-${code3}`
    }
    getForm() {
        return <SForm
            ref={(ref) => { this.form = ref }}
            props={{
                col: "xs-12",
                row: true,
            }}
            style={{
                justifyContent: "space-between",
            }}
            inputProps={{
                customStyle: "calistenia",
            }}
            inputs={{
                codigo: { label: "Codigo", type: "text", isRequired: true, col: "xs-12 sm-5.8", defaultValue: this.buildCode() },
                fecha: { label: "Fecha", type: "date", isRequired: true, col: "xs-12 sm-5.8", defaultValue: new SDate().toString("yyyy-MM-dd") },
                nit: { label: "Nit", type: "text", isRequired: false, col: "xs-12 sm-5.8" },
                nombre_cliente: { label: "Cliente", type: "text", isRequired: true, col: "xs-12 sm-5.8" },
            }}
            onSubmit={(data) => {
                var dataDetalle = Carrito.Actions.getAll(this.props);
                var total = 0;
                if(!dataDetalle) return;
                var dataDetalleFinal = Object.keys(dataDetalle).map((key, index) => {
                    try {
                        var obj = dataDetalle[key];
                        var subTotal = (obj.cantidad_carrito * obj.precio_venta - ((obj.cantidad_carrito * obj.precio_venta) * (obj.descuento / 100)));
                        total += subTotal;
                        console.log(obj);
                        return {
                            nombre: obj.nombre,
                            descripcion: "# " + (index + 1) + " | " + obj.codigo + " | -" + obj.descuento.toFixed(0) + "%",
                            cantidad: obj.cantidad_carrito,
                            precio: obj.precio_venta,
                            sub_total: subTotal,
                            tipo_articulo: 1,
                            key_articulo: obj.key,
                            descuento: obj.descuento,
                            key_sucursal: obj.key_sucursal,
                        };
                    } catch (e) {
                        return null;
                    }
                })
                data.total = total;
                SSocket.send({
                    component: "venta",
                    type: "registro",
                    estado: "cargando",
                    data: data,
                    detalle: dataDetalleFinal,
                })

            }}
        />
    }
    getDelete(obj) {
        return <SView center style={{
            width: 22,
            height: 22,
            position: "absolute",
            top: 0,
            right: 0,
        }} onPress={() => {
            this.props.dispatch({
                component: "carrito",
                type: "delete",
                estado: "exito",
                data: obj,
            });
        }}>
            <SIcon name={"Delete"} />
        </SView>
    }
    getDescuento(obj) {
        if (obj.descuento <= 0) {
            return null;
        }
        return <SView center style={{
            width: 53,
            height: 18,
            position: "absolute",
            bottom: -4,
            right: -12,
            borderRadius: 100,
            backgroundColor: STheme.color.danger + "99",
        }} onPress={() => {

        }} center>
            <SText fontSize={12} bold>-{obj.descuento.toFixed(0)}%</SText>
        </SView>
    }

    getLista() {
        var data = Carrito.Actions.getAll(this.props);
        return new SOrdenador([{ key: "data_add", order: "asc", peso: 1, }]).ordernarObject(data).map((key, index) => {
            var obj = data[key];
            return <SView col={"xs-12"} center style={{
                padding: 4,
            }}>
                <SView col={"xs-12"} center card style={{
                    padding: 4,
                }} row>
                    <SView col={"xs-12 md-6"} row height={50}>
                        <SView width={50} height={50}>
                            <SImage
                                enablePreview
                                style={{ width: 50, height: 50, borderRadius: 4 }}
                                src={`${SSocket.api.root}${obj.url_foto}`}
                            />
                        </SView>
                        <SView flex height style={{
                            justifyContent: "center",
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}>
                            <SText fontSize={14}>{obj.nombre}</SText>
                            <SText fontSize={10}>{obj.codigo}</SText>
                        </SView>
                    </SView>
                    <SView col={"xs-12 md-6"} row height={60}>
                        <SView flex></SView>
                        <SView height center width={100} card style={{ padding: 4 }}>
                            <SText fontSize={10} color={"#999"}>Precio</SText>
                            <SInput
                                editable={false}
                                isRequired
                                ref={(ref) => this._ref[obj.key + "precio"] = ref}
                                defaultValue={obj.precio_venta}
                                type={"money"}
                                customStyle={"calistenia"}
                                style={{
                                    fontSize: 12,
                                    textAlign: "center",

                                }}
                                onChangeText={(text) => {
                                    if (obj.precio_venta == text) return text;
                                    obj.precio_venta = text;
                                    new SThread(750, "tVenta", true).start(() => {
                                        var precio_venta = obj.precio_venta;
                                        if (parseFloat(precio_venta) < parseFloat(obj.precio)) {
                                            precio_venta = parseFloat(obj.precio).toFixed(2)
                                        }
                                        if (precio_venta) {
                                            obj.precio_venta = precio_venta;
                                            this._ref[obj.key + "precio"].setValue(precio_venta);
                                            this._ref[obj.key + "sub_total"].setValue((parseFloat(precio_venta) * parseFloat(obj.cantidad_carrito)).toFixed(2));
                                            this.props.dispatch({
                                                component: "carrito",
                                                type: "registro",
                                                data: {
                                                    ...obj,
                                                },
                                                estado: "exito",
                                            })
                                        }
                                    })
                                }}
                            // onBlur={() => {


                            // }}
                            />
                        </SView>
                        <SView height center width={60} style={{ padding: 4 }}>
                            <SText fontSize={10} color={"#999"}>Cantidad</SText>
                            <SInput defaultValue={obj.cantidad_carrito} type={"number"} customStyle={"calistenia"} style={{
                                fontSize: 12,
                                textAlign: "center",
                                paddingStart: 0,
                                // width: "100%",
                                // backgroundColor: "#fff",
                            }}
                                isRequired
                                onChangeText={(text) => {
                                    var cantidad = text;
                                    if (parseInt(text) > parseInt(obj.cantidad)) {
                                        cantidad = obj.cantidad + ""
                                        // return obj.cantidad + "";
                                    }
                                    if (parseInt(text) <= 0) {
                                        cantidad = 1 + "";
                                        // return 1 + "";.
                                    }

                                    if (cantidad) {
                                        obj.cantidad_carrito = cantidad;
                                        this._ref[obj.key + "sub_total"].setValue((parseFloat(obj.precio_venta) * parseFloat(obj.cantidad_carrito)).toFixed(2));
                                        this.props.dispatch({
                                            component: "carrito",
                                            type: "registro",
                                            data: {
                                                ...obj,
                                            },
                                            estado: "exito",
                                        })
                                    }

                                    return cantidad;

                                }}
                            />
                        </SView>
                        <SView height center width={100} card>
                            <SText fontSize={10} color={"#999"}>Sub-Total</SText>
                            <SInput isRequired ref={(ref) => this._ref[obj.key + "sub_total"] = ref}
                                defaultValue={(obj.cantidad_carrito * obj.precio_venta - ((obj.cantidad_carrito * obj.precio_venta) * (obj.descuento / 100))).toFixed(2)}
                                type={"money"} customStyle={"calistenia"}
                                style={{
                                    fontSize: 12,
                                    textAlign: "center",

                                }}
                                onChangeText={(text) => {
                                    var precio_original = obj.cantidad_carrito * obj.precio_venta;
                                    var precio = parseFloat(text);
                                    var descuento = precio_original - precio;
                                    descuento = (descuento / precio_original) * 100;
                                    console.log(descuento);
                                    obj.descuento = descuento;
                                    new SThread(750, "tVenta", true).start(() => {
                                        this.props.dispatch({
                                            component: "carrito",
                                            type: "registro",
                                            data: {
                                                ...obj,
                                            },
                                            estado: "exito",
                                        })
                                    })
                                }}
                            />
                        </SView>
                    </SView>
                    {this.getDelete(obj)}
                    {this.getDescuento(obj)}
                </SView>
            </SView>
        })
    }
    getTotal() {
        var data = Carrito.Actions.getAll(this.props);
        var total = 0;
        Object.keys(data).map((key, index) => {
            var obj = data[key];
            total += (obj.cantidad_carrito * obj.precio_venta - ((obj.cantidad_carrito * obj.precio_venta) * (obj.descuento / 100)));
        })
        return <SView col={"xs-12"} center style={{
            padding: 4,
        }}>
            <SView col={"xs-12"} center card height={40} style={{
                padding: 4,
            }}>
                <SView col={"xs-12"} row height>
                    <SView flex height center>
                    </SView>
                    <SView height width={200} card style={{
                        alignItems: "flex-end",
                        justifyContent: "center",
                        paddingEnd: 8,
                    }}>
                        <SText bold fontSize={16}>Bs. {(total).toFixed(2)}</SText>
                    </SView>
                </SView>
            </SView>
        </SView>

    }
    render() {
        if (this.props.state.carritoReducer.venta_exitosa) {
            this.props.state.carritoReducer.venta_exitosa = false;
            SPopup.alert("Venta exitosa");
            SNavigation.goBack();
        }
        return (
            <SPage title={"Carrito"}>
                <SView col={"xs-12"} center>
                    <SView col={"xs-11 md-9 xl-6"} center>
                        {this.getForm()}
                    </SView>
                    <SHr />
                    <SView col={"xs-11 md-9 xl-6"} center>
                        <SView col={"xs-12"} center row card style={{
                            padding: 4,
                        }}>
                            <SView flex>
                                <SText>Articulos</SText>
                            </SView>
                            <SView width={40} height={40} onPress={() => {
                                SNavigation.navigate("repuesto");
                            }}>
                                <SIcon name={"Add"} />
                            </SView>
                        </SView>
                        <SHr />
                        <SView col={"xs-12"} center>

                            {this.getLista()}
                            {this.getTotal()}
                            <SHr />
                            <SButtom props={{
                                type: "danger"
                            }} onPress={() => {
                                this.form.submit();
                            }}>
                                VENDER
                            </SButtom>
                        </SView>
                    </SView>
                </SView>
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(CarritoPage);