import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { SButtom, SLoad, SPopup, SText, STheme, SView } from 'servisofts-component';
import almacen from '../../almacen';
import Canvas from '../../almacen/Pages/Canvas/Canvas';

class SelectAlmacen extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    lista() {
        // return <SText>{this.props.id_suc}</SText>
        var data = almacen.Actions.getAll(this.props.id_suc, this.props);
        if (!data) return <SLoad />;
        return <Canvas data={data} ref={(ref) => { this.canvas = ref }} />
    }
    render() {
        return (
            <SView col={"xs-12 md-8"} center backgroundColor={STheme.color.background + "ee"}
                style={{
                    borderRadius: 10,
                }} withoutFeedback >
                <SView height={50} center>
                    <SText center fontSize={10}>Selecciona el almacen en el que guardaremos los repuestos</SText>
                </SView>
                <SView col={"xs-12"} flex center>
                    {this.lista()}
                </SView>
                <SView height={70} center>
                    <SButtom
                        onPress={() => {
                            if (this.canvas) {
                                var almacen = this.canvas.getSelect();
                                if (!almacen) {
                                    SPopup.alert("Selecciona un almacen");
                                    return;
                                }
                                if (this.props.onConfirm) {
                                    this.props.onConfirm(almacen);
                                }
                            }

                        }}
                        props={{
                            type: "danger"
                        }}>Aceptar</SButtom>
                </SView>
            </SView>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(SelectAlmacen);