
//REDUCER
import ventaReducer from "./Reducer/ventaReducer"
//PAGES
import Lista from "./Pages/Lista"
const Pages = {
    "venta": Lista,
}

const Reducers = {
    ventaReducer
}


import Actions from "./Actions"
export default {
    Pages,
    Reducers,
    Actions

}