import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SIcon, SLoad, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component';
import Parent from '../index'
import SSocket from 'servisofts-socket'
import { SImage } from 'servisofts-component';
import usuario from '../../../../Usuario/Components/usuario';
import { SButtom } from 'servisofts-component';
import container from '../../container';
import sucursal from '../../sucursal';
import AddCarrito from './AddCarrito';
class Lista extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getContent() {
        var data = Parent.Actions.getAll(this.props);
        var containers = container.Actions.getAll(this.props);
        var sucursales = sucursal.Actions.getAll(this.props);
        // var usuarios = usuario.Actions.getAll(this.props);
        if (!data) return <SLoad />;
        if (!containers) return <SLoad />;
        if (!sucursales) return <SLoad />;
        return <STable2

            header={[
                { key: "index", label: "#", width: 30 },
                // { key: "key", label: "id", width: 50, order: "desc", render: (item) => { return parseFloat(item)} },
                { key: "codigo", label: "Codigo", width: 90 },
                {
                    key: "url_foto", label: "url_foto", width: 50, component: (key) => {
                        console.log(SSocket.api.root+key)
                        return <SView width={40} height={40} onPress={() => {
                        }}>
                            <SImage src={SSocket.api.root + key} enablePreview />
                        </SView>
                    }
                },
                { key: "nombre", label: "nombre", width: 250 },
                { key: "cantidad", label: "Cant.", width: 45, center: true },
                { key: "precio", label: "precio", width: 100, center: true, render: (price) => `Bs. ${parseFloat(price ? price : 0).toFixed(2)}` },
                {
                    key: "key_container", label: "key_container", center: true, width: 150, render: (key) => {
                        if (containers[key]) {
                            return containers[key].nombre
                        }
                        return "";
                    }
                },
                {
                    key: "key_sucursal", label: "Sucursal", center: true, width: 120, render: (key) => {
                        if (sucursales[key]) {
                            return sucursales[key].nombre
                        }
                        return "";
                    }
                },
                // {
                //     label: "Fotos",
                //     key: "fotos",
                //     width: 200,
                //     render: (data) => {
                //         if (!data) return null;
                //         var fotos = [];
                //         data.map((item, index) => {
                //             fotos.push(`${SSocket.api.root}${item.url}`);
                //         })
                //         return fotos;
                //     },
                //     component: (data) => {
                //         if (!data) return <SView />
                //         return <SView row>
                //             {data.map((obj) => {
                //                 return <SImage
                //                     enablePreview
                //                     style={{ width: 35, height: 35, borderRadius: 4, margin: 2, }}
                //                     src={obj}
                //                 />
                //             })}
                //         </SView>

                //     }
                // },
                // { key: "cardex", label: "cardex", width: 100, },

                { key: "fecha_on", label: "fecha registro", width: 120, order: "desc", render: (key) => new SDate(key).toString("yyyy-MM-dd hh:mm") },
                { key: "key-editar", label: "Editar", width: 50, center: true, component: (item) => { return <SView onPress={() => { SNavigation.navigate(Parent.component + "/editar", { key: item }) }}> <SIcon name={"Edit"} width={35} /></SView> } },
                {
                    key: "key-eliminar", label: "Elimnar", width: 50, center: true, component: (item) => {
                        return <SView onPress={() => {
                            SPopup.confirm({
                                title: "Seguro que desea eliminar?", 
                                message:`${data[item].nombre}`,
                                onPress: () => {
                                    Parent.Actions.eliminar(data[item], this.props);
                                }
                            })
                        }}> <SIcon name={"Delete"} width={35} />
                        </SView>
                    }
                },
                {
                    key: "key-", label: "Vender", width: 60, component: (item) => {
                        var obj = data[item];
                        return <SView col={"xs-12"} height center onPress={() => {
                            SPopup.open({
                                key: "addCarrito",
                                content: <AddCarrito data={obj} />
                            })
                        }}>
                            <SIcon name={"Carrito"} width={35} height={35} />
                        </SView>
                    }
                },
                // { key: "key-expensa", label: "Expensa", width: 50, center: true, component: (item) => { return <SView onPress={() => { SNavigation.navigate("expensa", { key_condominio: item }) }}> <SIcon name={"Money"} width={35} /></SView> } },

            ]}
            data={data}
            limit={25}
            filter={(item) => {
                return item.estado == "1";
            }}
        />
    }

    render() {
        return (
            <SPage title={'Lista de ' + Parent.component} disableScroll center>
                {this.getContent()}


                <SButtom type={"float"} onPress={() => {
                    SNavigation.navigate(Parent.component + "/registro")
                }}>
                    <SIcon name={"Add"} height={50} />
                </SButtom>


            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Lista);