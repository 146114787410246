

const initialState = {
    repuestos: {},
    almacen: {},
}

export default (state, action) => {
    if (!state) return initialState
    if (action.component == "venta") {
        switch (action.type) {
            case "getAll": getAll(state, action); break;
            case "getAllRepuestos": getAllRepuestos(state, action); break;
            case "getAllSubAlmacen": getAllSubAlmacen(state, action); break;
        }


        state.type = action.type
        state.estado = action.estado
        state.error = action.error
        return { ...state }
    }
    return state
}

const getAll = (state, action) => {
    if (action.estado == "exito") {
        state.data = action.data;
    }
}
const getAllRepuestos = (state, action) => {
    if (action.estado == "exito") {
        state.repuestos[action.id_sucursal] = action.data;
    }
}
const getAllSubAlmacen = (state, action) => {
    if (action.estado == "exito") {
        state.almacen[action.id_sucursal] = action.data;
    }
}

