import React, { Component } from 'react';
import Svg, { Rect, Text } from 'react-native-svg';
import { SOrdenador, SText, STheme, SView } from 'servisofts-component';

export default class Canvas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            select: null,
        };
    }

    getSelect() {
        return this.state.select;
    }
    getData() {
        var data = this.props.data;
        if (!data) return;

        return new SOrdenador([{
            key: "key",
            order: "asc",
        }]).ordernarObject(data).map((index) => {
            var item = data[index];
            var select = null;
            if (this.state.select) {
                if (this.state.select.key === item.key) {
                    select = this.state.select;
                }
            }
            return <>
                <Rect x={item.x1} y={item.y1} width={item.x2 - item.x1} height={item.y2 - item.y1} rx="4"
                    stroke={STheme.color.secondary}
                    fill={!select ? "transparent" : STheme.color.primary + 77}
                    onClick={() => {
                        this.setState({ select: item });
                    }}
                    onPress={() => {
                        this.setState({ select: item });
                        // alert(JSON.stringify(item));
                    }}
                />
                <Text
                    fill={STheme.color.secondary}
                    fontSize="12"
                    x={item.x1 + 4}
                    y={item.y1 + 16}
                >{item.nombre}</Text>
            </>;
        })
    }
    render() {
        return (<SView col={"xs-12 sm-11 md-10 lg-8 xl-7"} height>
            <Svg viewBox="0 0 1000 800" width={"100%"} height={"100%"} fill={"transparent"}>
                <Rect width="1000" height="800" rx="4" stroke={STheme.color.secondary} fill={STheme.color.background+"99"}/>
                {this.getData()}

            </Svg>
        </SView>
        );
    }
}
