import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { SDate, SIcon, SLoad, SNavigation, SPage, STable2, SText, STheme, SView } from 'servisofts-component';
import Parent from '..';

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    getLista() {
        var data = Parent.Actions.getAll(this.props);
        if (!data) return <SLoad />;
        return <STable2
            
            header={[
                { key: "index", label: "#", width: 30 },
                { key: "codigo", label: "codigo", width: 100 },
                { key: "fecha", label: "Fecha", width: 100, order: "desc", render: (item) => { return new SDate(item).toString("yyyy-MM-dd") } },
                { key: "nit", label: "Nit", width: 100 },
                { key: "nombre_cliente", label: "Cliente", width: 200 },
                {
                    key: "detalle-cant", label: "# Productos", width: 80, center: true, render: (item) => {
                        return item.length;
                    }
                },
                {
                    key: "detalle", label: "Detalle", width: 300, render: (item) => {
                        var str = "";
                        item.map((obj, i) => {
                            str += `${i + 1}. ${obj.nombre}\n`
                        })
                        return str;
                    }
                },
                {
                    key: "detalle-precio", label: "Precio U", width: 100, render: (item) => {
                        var str = "";
                        item.map((obj, i) => {
                            str += `Bs. ${obj.precio}\n`
                        })
                        return str;
                    }
                },
                {
                    key: "detalle-cantidad", label: "Cantidad", width: 80, center: true, render: (item) => {
                        var str = "";
                        item.map((obj, i) => {
                            str += `${obj.cantidad}\n`
                        })
                        return str;
                    }
                },
                {
                    key: "detalle-descuento", label: "Descuento", width: 80, center: true, render: (item) => {
                        var str = "";
                        item.map((obj, i) => {
                            if (!obj.descuento) return;
                            str += `${parseFloat(obj.descuento).toFixed(2)} %\n`
                        })
                        return str;
                    }
                },
                {
                    key: "detalle-subTotal", label: "Sub Total", width: 100, render: (item) => {
                        var str = "";
                        item.map((obj, i) => {
                            str += `Bs. ${obj.sub_total}\n`
                        })
                        return str;
                    }
                },

                { key: "total", label: "Total", width: 100, render: (item) => { return "Bs. " + item.toFixed(2) } },

            ]}
            data={data}
            limit={50}
            rowHeight={200}
        >
        </STable2>
    }
    render() {
        return (
            <SPage title={"Ventas"} disableScroll>
                <SView col={"xs-12"} height>
                    {this.getLista()}
                </SView>
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);