import { SStorage } from "servisofts-component";


const initialState = {
    data: {},
}
const componet = "carrito";

export default (state, action) => {
    if (!state) {
        SStorage.getItem(componet, (data) => {
            if (data) {
                initialState.data = JSON.parse(data);
            }
        });
        return initialState
    }

    if (action.component == componet) {
        switch (action.type) {
            case "registro": registro(state, action); break;
            case "delete": delete_d(state, action); break;

        }
        state.type = action.type
        state.estado = action.estado
        state.error = action.error
        SStorage.setItem(componet, JSON.stringify(state.data));
        return { ...state }
    }
    if (action.component == "venta" && action.type == "registro") {
        if (action.estado == "exito") {
            state.data = {};
            state.venta_exitosa = true;
            SStorage.setItem(componet, JSON.stringify(state.data));
            return { ...state }
        }
    }
    return state
}

const registro = (state, action) => {
    if (action.estado == "exito") {
        if (state.data) {
            if (state.data[action.data.key]) {
                // action.data.data_add =  state.data[action.data.id].data_add;
                return;
            }
            state.data[action.data.key] = action.data;
        }
    }
}
const delete_d = (state, action) => {
    if (state.data) {
        delete state.data[action.data.key]
    }
}

