import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { SButtom, SHr, SImage, SInput, SLoad, SNavigation, SPage, SPopup, SText, STheme, SView } from 'servisofts-component';
import Sucursal from '../../sucursal';
import SSocket from 'servisofts-socket'

class AddCarrito extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
        };
    }

    getCantidad() {
        return <SInput
            ref={(ref) => { this.cantidad = ref; }}
            type={"number"}
            value={this.state.value}
            col={"xs-6"}
            label={"Cantidad a vender:"}
            customStyle={"calistenia"}
            autoFocus={true}
            isRequired
            onChangeText={(text) => {
                if (text > this.props.data.cantidad) {
                    text = this.props.data.cantidad;
                }
                if (text == "0") {
                    text = 1;
                }
                this.setState({ value: text });
            }}
        />
    }
    getSucursal = (obj) => {
        var surusales = Sucursal.Actions.getAll(this.props);
        if (!surusales) return <SLoad />;
        var sucursal = surusales[this.props.data.key_sucursal];
        if (!sucursal) return <SLoad />;
        return <SText color={"#999"} fontSize={10}>{`Sucursal:`} <SText fontSize={16} >{sucursal.nombre}</SText></SText>
    }
    render() {
        var obj = this.props.data;

        return (
            <SView col={"xs-12 md-8 xl-6"} height={400} withoutFeedback backgroundColor={STheme.color.background} style={{
                borderRadius: 10,
                maxHeight: "100%",
            }}>
                {SPage.backgroundComponent}
                <SView col={"xs-12"} center>
                    <SHr />
                    <SText fontSize={14} bold >Agregar repuesto al carrito?</SText>
                    <SHr />
                    <SHr />
                    <SImage
                        enablePreview
                        style={{ width: 60, height: 60, borderRadius: 4 }}
                        src={SSocket.api.root + obj.url_foto}
                    />
                    <SText fontSize={18} bold>{obj.nombre}</SText>
                    <SHr />
                    <SText color={"#999"} fontSize={10}>{`Precio:`} <SText fontSize={16}>Bs. {obj.precio}</SText></SText>
                    <SHr />
                    <SText color={"#999"} fontSize={10}>{`U. disponibles:`} <SText fontSize={16}>{obj.cantidad}</SText></SText>
                    <SHr />
                    {this.getSucursal(obj)}
                    <SHr />
                    {this.getCantidad()}
                    <SHr />
                    <SButtom props={{
                        type: "danger"
                    }} onPress={() => {
                        var cantidad = this.state.value;
                        if (cantidad <= 0) return;
                        this.props.dispatch({
                            component: "carrito",
                            type: "registro",
                            data: {
                                ...obj,
                                cantidad_carrito: cantidad,
                                precio_venta: obj.precio.toFixed(2),
                                descuento: 0,
                                data_add: new Date().getTime()
                            },
                            estado: "exito",
                        })
                        SPopup.close("addCarrito");
                        SNavigation.navigate("carrito");
                    }}>Agregar</SButtom>
                </SView>
            </SView>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(AddCarrito);