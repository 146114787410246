import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { SButtom, SForm, SHr, SLoad, SNavigation, SPage, SPopup, SText, STheme, SView } from 'servisofts-component';
import Repuesto from '..';
import DropFile from '../../../../../Components/DropFile';
import Container from '../../container';
import Sucursal from '../../sucursal';
import SelectAlmacen from './SelectAlmacen';
import SSocket from 'servisofts-socket';
class Registro extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.key = SNavigation.getParam("key", null);
    }

    buildCode = () => {
        var code = (new Date().getTime()) % 1000;
        code = code.toFixed(0)
        var code2 = (new Date().getTime() / 1000) % 1000;
        code2 = code2.toFixed(0)
        var code3 = (new Date().getTime() / 100000) % 1000;
        code3 = code3.toFixed(0)
        return `${code}-${code2}-${code3}`
    }
    getForm() {
        if (this.props.state.repuestoReducer.estado == "exito" && this.props.state.repuestoReducer.type == "registro") {
            this.props.state.repuestoReducer.estado = "";
            var lastregister = this.props.state.repuestoReducer.lastRegister;
            this.form.uploadFiles(SSocket.api.root + "upload/repuesto/" + lastregister.key);
            SPopup.close("selectAlmacen");
            SNavigation.goBack();
            return <SLoad />
        }
        var sucursales = Sucursal.Actions.getAll(this.props)
        var container = Container.Actions.getAll(this.props)
        var data = {};
        if (this.key) {
            data = Repuesto.Actions.getByKey(this.key, this.props);
            if (!data) return <SLoad />;
        }
        if (!sucursales) return <SLoad />;
        if (!container) return <SLoad />;
        var sucOptions = []
        sucOptions.push({
            key: "",
            content: "vacio"
        });
        Object.keys(sucursales).map((key) => {
            sucOptions.push({
                key: key,
                content: sucursales[key].nombre
            })
        })
        var containerOptions = []

        Object.keys(container).map((key) => {
            if (!data.key_container) data.key_container = key;
            containerOptions.push({
                key: key,
                content: <SText color={container[key].color}>{container[key].nombre}</SText>
            })
        })

        return <SForm
            ref={(ref) => { this.form = ref }}
            props={{
                col: "xs-12",
                row: true,
            }}
            style={{
                justifyContent: "space-between",
            }}
            inputProps={{
                customStyle: "calistenia",
            }}
            inputs={{
                foto_p: { type: "image", isRequired: false, defaultValue: `${SSocket.api.root}${Repuesto.component}/${this.key}` },
                codigo: { label: "Codigo", type: "text", isRequired: true, defaultValue: data.codigo, defaultValue: this.buildCode(), },
                nombre: { label: "Nombre", type: "text", isRequired: true, defaultValue: data.nombre },
                precio: { label: "Precio", type: "money", isRequired: true, col: "xs-5.5", defaultValue: parseFloat(data.precio ? data.precio : 0).toFixed(2) },
                cantidad: { label: "Cantidad", type: "number", isRequired: true, defaultValue: 1, col: "xs-5.5", value: data.cantidad },
                sucursal: { label: "Sucursal", type: "select", options: sucOptions, isRequired: true, defaultValue: data.key_sucursal },
                key_container: { label: "Container", type: "select", options: containerOptions, isRequired: true, defaultValue: data.key_container },
            }}
            onSubmit={(data) => {
                if (this.key) return;
                // var files = this.dropfile.getFiles();
                SPopup.open({
                    key: "selectAlmacen",
                    content: <SelectAlmacen id_suc={data.sucursal} onConfirm={(almacen) => {
                        // SNavigation.navigate("repuesto");
                        if (this.props.state.repuestoReducer.estado == "cargando") return;
                        SSocket.send({
                            component: "repuesto",
                            type: "registro",
                            estado: "cargando",
                            data: data,
                            key_sucursal: data.sucursal,
                            key_almacen: almacen.key,
                            key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
                        });
                        SPopup.close("selectAlmacen");

                    }} />,
                })

            }}
        />
    }
    render() {
        return (
            <SPage title={"Registro de repuesto"}>
                <SView col={"xs-12"} center>
                    <SView col={"xs-11 md-6 xl-4"} center>
                        {/* <DropFile ref={(ref) => { this.dropfile = ref; }} /> */}
                        {this.getForm()}
                        <SView col={"xs-12"} center row>
                            <SButtom
                                onPress={() => {
                                    if (this.key) {
                                        return;
                                    }
                                    this.form.submit();
                                }}
                                loading={this.props.state.repuestoReducer.estado == "cargando"}
                                props={{
                                    type: "outline"
                                }}
                            >{this.key ? "actualizar" : "registrar"}</SButtom>
                            <SHr />
                            {/* <SButtom
                                onPress={() => {
                                    // var files = this.dropfile.getFiles();
                                    // if(!files) return null;
                                   this.form.uploadFiles(SSocket.api.root + "upload/repuestos/repuesto/"+this.key);
                                }}
                                props={{type: "outline"}}
                            >{"subir fotos"}</SButtom> */}
                        </SView>
                        <SHr height={80} />
                    </SView>
                </SView>
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Registro);